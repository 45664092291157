<template>
  <div class="hall">
    <!-- 图 -->
    <div class="kf_ban" id="ba"></div>
    <div class="wrap-all-mode">
      <div class="w">
        <!-- tab -->
        <ul class="tab-type-all">
          <li
            class="each-tab"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ active: currentClass == index }"
            @click="toggleTab(item.tab_con, index, item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
        <!-- 企业需求 -->
        <div class="all-typeBox">
          <!-- 企业需求 -->
          <div class="each-box-type each-box-typem clearfix">
            <div class="lf-type-box left">
              <div class="top-tab-inner top-tab-innerreq">
                <div class="top-title">企业需求</div>
                <div class="tab-btn" @click="moreQ">
                  <span>查看更多</span>
                </div>
              </div>
            </div>
            <div class="rg-type-box rg-type-boxm right">
              <div class="tag">
                <ul>
                  <li
                    v-for="i in rgtagList"
                    :key="i.id"
                    class="col"
                    @click="onNeed(i.id)"
                  >
                    <div style="position: relative" :title="i.title">
                      <p>{{ i.title }}</p>
                      <i
                        class="el-icon-arrow-right"
                        style="
                          position: absolute;
                          top: 0px;
                          right: 0;
                          color: #333;
                        "
                      ></i>
                    </div>
                    <div class="each-tags-box">
                      <blockquote>
                        <span
                          class="each-tags"
                          v-for="item in i.label"
                          :key="item.id"  :title="item.name"
                          >{{ item.name }}</span
                        >
                      </blockquote>
                    </div>

                    <div class="red">
                      <span class="left">需求预算</span>
                      <span class="right red1">面议</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 专家推荐 -->
          <div class="each-box-type each-box-type1 clearfix">
            <div class="lf-type-box left">
              <div class="top-tab-inner">
                <div class="top-title">专家推荐</div>
                <div class="tab-btn" @click="moreZ">
                  <span>查看更多</span>
                </div>
              </div>
            </div>
            <!-- <div class="rg-type-box right">
              <ul class="all-types">
                <li class="each-type-wrap" v-for="(item, idx) in zj" :key="idx">
                  <div class="inner-type-box">
                    <div class="top-img">
                      <img src="../../../assets/img/qiyeyun/zj.png" alt="" />
                    </div>

                    <div class="top-title" :title="item.name">
                      {{ item.name }}<i class="el-icon-arrow-right"></i>
                    </div>

                    <div class="type-name">个人简介</div>
                    <div class="direction">
                      {{ item.resume }}
                    </div>
                     <span class="count-box-expert" @click="expert(item.id)"
                      ><span>联系专家</span> <span> </span
                    ></span>
                  </div>
                </li>
              </ul>
            </div> -->

            <div class="rg-type-box right" style="padding: 15px 35px 20px">
              <ul class="items all-types">
                <li
                  class="link"
                  v-for="(item, idx) in zj"
                  :key="idx"
                  @click="expert(item.id)"
                >
                  <h2>
                    <span> {{ item.name }}</span>
                  </h2>
                  <div class="count-box-expert">
                    <i class="el-icon-school blue"></i
                    ><span>{{ item.company_name }}</span>
                  </div>
                  <p class="direction">个人简介：{{ item.resume }}</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- 成果推荐 -->
          <div class="each-box-type each-box-type1 each-box-type2 clearfix">
            <div class="lf-type-box left">
              <div class="top-tab-inner">
                <div class="top-title">成果推荐</div>
                <div class="tab-btn" @click="moreC"><span>查看更多</span></div>
              </div>
            </div>
            <div class="rg-type-box right">
              <ul class="all-types all-types2">
                <li class="each-type-wrap" v-for="(item, idx) in cg" :key="idx">
                  <div class="inner-type-box">
                    <div class="top-type">
                      <div class="top-title" :title="item.name">
                        {{ item.name }}
                      </div>
                      <div class="top-arrow">
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                    <div class="type-name">成果阶段</div>
                    <div class="type-sub-name">{{ item.projects }}</div>
                    <!-- <ul class="all-type-labels">
                      <li
                        :title="i"
                        class="each-type"
                        v-for="(i, index) in item.jd"
                        :key="index"
                      >
                        {{ i }}
                      </li>
                    </ul> -->
                    <div class="type-name">成果领域</div>
                    <div class="type-sub-name">{{ item.territory.name }}</div>
                    <!-- <ul class="all-type-labels">
                      <li
                        :title="it.name"
                        class="each-type"
                        v-for="(it, index) in item.territory"
                        :key="index"
                      >
                        {{ it.name}}
                      </li>
                    </ul> -->
                    <span class="count-box-expert" @click="xq(item.id)"
                      ><span>我要联系</span></span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 请在下表留下您的信息 -->
          <Form></Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Hall, DType, HallQ } from "@/common/js/api";
import Form from "./submit_form";
export default {
  name: "HallNel",
  components: {
    Form,
  },
  data() {
    return {
      currentClass: 0, // 默认选中按钮样式
      tabList: [
        // {
        //   title: "全部",
        // },
        // {
        //   title: "电子信息",
        // },
        // {
        //   title: "生物与新药学",
        // },
        // {
        //   title: "新材料",
        // },
        // {
        //   title: "新能源与节能",
        // },
        // {
        //   title: "先进制造与自动化",
        // },
      ],
      rgtagList: [],
      zj: [],
      cg: [],
      type: 0,
    };
  },
  created() {
    this.getHall();
    this.DType();
  },
  methods: {
    //

    DType() {
      DType()
        .then((res) => {
          // console.log(res);
          var a = { name: "全部" };
          res.data.unshift(a);
          this.tabList = res.data;
        })
        .catch(() => {});
    },
    // 获取需求大厅
    getHall() {
      // 专家推荐 成果推荐
      Hall()
        .then((res) => {
          // console.log(res);
          // this.rgtagList = res.data.one.slice(0, 6);
          this.zj = res.data.two.slice(0, 6);
          this.cg = res.data.there.slice(0, 4);
        })
        .catch(() => {});
      // 企业需求
      HallQ({
        type: this.type,
      })
        .then((res) => {
          // console.log(res);
          this.rgtagList = res.data.slice(0, 6);
          for (let i = 0; i < this.rgtagList.length; i++) {
            if (this.rgtagList[i].label.length > 4) {
              this.rgtagList[i].label = this.rgtagList[i].label.slice(0, 4);
            }
          }
        })
        .catch(() => {});
    },
    // 企业需求  --- 查看更多
    moreQ() {
      this.$router.push({
        path: "/more_need",
      });
    },
    //   前往需求详情
    onNeed(id) {
      this.$router.push({
        path: "/need_details",
        query: {
          id: id,
        },
      });
    },
    // 专家推荐  --- 查看更多
    moreZ() {
      this.$router.push({
        path: "/scientist",
      });
    },
    // 专家详情
    expert(id) {
      this.$router.push({
        path: "/expert",
        query: {
          id: id,
        },
      });
    },
    // 成果推荐 -----    查看更多
    moreC() {
      this.$router.push({
        path: "/achievement",
      });
    },
    // 成果详情
    xq(id) {
      this.$router.push({
        path: "/Achievement_details",
        query: {
          id: id,
        },
      });
    },
    // tab
    toggleTab(tab_con, currentClass, id) {
      //   this.currentTab = tab_con; // 选中tab内容块展示
      this.currentClass = currentClass; // 选中tab样式
      this.type = id;
      this.getHall();
    },
  },
};
</script>

<style scoped lang="less">
.hall {
  background-color: rgba(248, 249, 250, 100);
  padding-bottom: 144px;
  margin-top: 135px;
  .kf_ban {
    background: url(../../../assets/img/qiyeyun/xqdt.png) no-repeat center;
    background-size: 100% 100%;
  }
  .wrap-all-mode {
    margin-top: 50px;
    background: url(../../../assets/img/qiyeyun/bg.png) no-repeat center;
    background-size: 100%;
    // tab
    .tab-type-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .each-tab {
        width: 160px;
        height: 47px;
        border-radius: 4px;
        color: #3892eb;
        text-align: center;
        line-height: 47px;
        font-size: 18px;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid #3892eb;
        border-radius: 5px;
      }
      .each-tab:hover,
      .active {
        font-weight: 700;
        color: #fff;
        background: #3892eb;
      }
    }
    // 企业需求
    .all-typeBox {
      margin: 30px 0 20px;
      .each-box-type {
        height: 380px;
        margin-bottom: 20px;
        .lf-type-box {
          width: 200px;
          height: 100%;
          background: url(../../../assets/img/qiyeyun/qyxq.png) no-repeat;
          background-size: cover;
          font-size: 20px;
          color: #fff;
          text-align: center;
          .top-tab-inner {
            padding-top: 72px;
            .tab-btn {
              cursor: pointer;
              background: #fff;
              font-size: 14px;
              color: #07a9e9;
              width: 90px;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              margin: 10px auto 0;
            }
          }
          .top-tab-innerreq {
            padding-top: 83px;
          }
        }
        .rg-type-box {
          width: 976px;
          padding: 20px 63px;
          background-color: #fff;
          height: 380px;
          .tag {
            ul {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              .col {
                cursor: pointer;
                width: 33%;
                // padding: 0 64px;
                padding: 0 46px;
                box-sizing: border-box;
                border-right: 1px dotted #bbb;
                p {
                  font-size: 18px;
                  font-weight: 700;
                  margin-top: 28px;
                  margin-bottom: 10px;
                  width: 90%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .each-tags-box {
                  display: flex;
                  align-items: center;
                  margin-bottom: 11px;
                  height: 50px;
                  .each-tags {
                    display: inline-block;
                    height: 20px;
                    line-height: 20px;
                    color: #666;
                    box-sizing: border-box;
                    border-radius: 4px;
                    margin-right: 6px;
                    margin-bottom: 5px;
                    border: 1px solid #eee;
                    text-align: center;
                    padding: 0 5px;
    width: 87px;
    overflow: hidden;
                  }
                }
                .red {
                  color: red;
                  padding-bottom: 25px;
                  margin-top: 5px;
                  margin-bottom: 29px;
                }
                .red1 {
                  font-size: 18px;
                }
              }
            }
          }
          .tag .col:nth-child(-n + 3) {
            border-bottom: 1px dotted #bbb;
          }
            .tag .col:nth-child(3n) {
            border-right: 0;
          }
           .tag .col:hover {
             box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
          }
        }
      }
      //   专家推荐
      .each-box-type1 {
        height: 300px;

        .lf-type-box {
          background: url(../../../assets/img/qiyeyun/zjtj.png) no-repeat;
          background-size: cover;
          .top-tab-inner .tab-btn {
            color: #f48e43;
          }
        }
        .rg-type-box {
          height: 300px;
          padding: 38px 35px 44px;
          width: 976px;

          .all-types {
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            // justify-content: center;
            font-size: 14px;

            //   专家推荐
            .link {
              box-sizing: border-box;
              position: relative;
              width: 32%;
              height: 122px;
              color: #696969;
              box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
              border-radius: 5px;
              margin: 10px 0 0 10px;
              -webkit-transition: all 0.2s linear;
              transition: all 0.2s linear;
              padding: 15px 18px 15px 20px;
              cursor: pointer;
              h2 {
                font-size: 18px;
                color: #00050a;
                font-weight: 700;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                line-height: 1.1;
                text-align: center;
              }
              .direction {
                font-size: 14px;
                margin-top: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                height: 38px;
                line-height: 1.3;
              }
              .count-box-expert {
                margin-top: 10px;
                margin-bottom: 10px;
                .blue {
                  color: #3892eb;
                  margin-right: 5px;
                }
              }
            }
            .link:hover {
              // transform: translateY(-4px);
              h2 {
                color: #3892eb;
              }
            }
            // 成果推荐
            .each-type-wrap:nth-child(4) {
              border-right: 0;
            }
            .each-type-wrap:hover {
              .count-box-expert {
                background: #3892eb;
                color: #fff;
              }
            }
            .each-type-wrap {
              box-sizing: border-box;
              padding: 0 20px;
              position: relative;
              width: 25%;
              height: 218px;
              border-right: 1px solid #eee;
              color: #696969;
              text-align: center;
              .top-img {
                width: 56px;
                height: 56px;
                margin: 0 auto 10px;
              }
              .top-title {
                font-size: 18px;
                color: #00050a;
                font-weight: 700;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                line-height: 1.1;
              }
              .type-name {
                margin-top: 15px;
                text-align: left;
                font-weight: 700;
                font-size: 16px;
              }

              .all-type-labels {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;

                .each-type {
                  text-align: center;
                  width: 56px;
                  box-sizing: border-box;
                  line-height: 1.1;
                  margin-right: 7px;
                  margin-bottom: 6px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
              .count-box-expert {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                width: 90px;
                height: 30px;
                line-height: 30px;
                border: 1px solid #3892eb;
                color: #3892eb;
                border-radius: 3px;
                cursor: pointer;
              }
            }
          }
        }
      }
      //   成果推荐
      .each-box-type2 {
        .lf-type-box {
          background: url(../../../assets/img/qiyeyun/cgtj.png) no-repeat;
          background-size: cover;
          .top-tab-inner .tab-btn {
            color: #63a0cc;
          }
        }
        .each-type-wrap {
          .top-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #00050a;
            margin-bottom: 42px;
            height: 40px;
          }
          .type-sub-name {
            font-size: 12px;
            margin-top: 8px;
            text-align: left;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.hall{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.hall .wrap-all-mode .tab-type-all .each-tab{
		width: 22%;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type{
		height: auto;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .lf-type-box{
		width: 100%;
		height: auto;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .lf-type-box{
		font-size: 2rem;
		
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .lf-type-box .top-tab-innerreq{
		padding-top: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .lf-type-box .top-tab-inner .tab-btn{
		width: auto;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.5rem;
		margin-top: 1.5rem !important;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul .col{
		width: 48%;
		padding: 0 1rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box{
		width: 100%;
		height: auto;
		padding: 0 !important;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul{
		justify-content: space-between;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag .col{
		border: 1px solid #bbbbbb !important;
		margin: 1rem 0;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul .col p{
		font-size: 1.6rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul .col .each-tags-box{
		height: auto;
		padding: 1rem 0;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul .col .each-tags-box .each-tags{
		height: 2rem;
		line-height: 2rem;
		font-size: 1.4rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul .col .red{
		font-size: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .rg-type-box .tag ul .col .red1{
		font-size: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type .lf-type-box .top-tab-inner{
		padding-top: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .link{
		width: 48%;
		margin: 1rem 0;
		height: auto;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types{
		justify-content: space-between;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .link h2{
		font-size: 1.8rem;
		padding-bottom: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .link{
		font-size: 1.6rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .link .direction{
		font-size: 1.6rem;
		margin: 1rem 0;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .each-type-wrap{
		height: auto;
		width: 48%;
		padding: 1rem;
		border: 1px solid #bbb;
		margin: 1rem 0;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .each-type-wrap .top-title{
		font-size: 1.8rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		width: 77%;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .each-type-wrap .type-name{
		font-size: 1.5rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type2 .each-type-wrap .type-sub-name{
		font-size: 1.4rem;
	}
	.hall .wrap-all-mode .all-typeBox .each-box-type1 .rg-type-box .all-types .each-type-wrap .count-box-expert{
		position: relative;
		left: auto;
		bottom: auto;
		font-size: 1.5rem;
		margin: 1.5rem auto;
		line-height: 3rem;
	}
	.submit-form-box .inner-form{
		padding: 0 !important;
	}
}
</style>
