<template>
	<div class="submit-form-box">
		<div class="inner-form">
			<div class="pea-title">
				<div class="one-content">
					<div class="copy-title">
						<div class="copy-con copy-con1">
							<span></span>
							<h3>请在下表留下您的信息</h3>
							<span></span>
						</div>
					</div>
				</div>
			</div>
			<div class="wrap-sub-title"><span class="center-subtitle">如果您想找技术、找产品、找咨询、找资金、找合作资源.....</span></div>
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="企业名称"><el-input v-model="form.corporate_name" placeholder="请填写企业名称"></el-input></el-form-item>
				<el-form-item label="需求类型">
					<el-radio-group v-model="form.type">
						<el-radio :label="item.id" v-for="item in type" :key="item.index">{{ item.name }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="联系人"><el-input v-model="form.name" placeholder="请填写联系人"></el-input></el-form-item>
				<el-form-item label="联系方式"><el-input v-model="form.phone" placeholder="请填写联系方式" maxlength="11"></el-input></el-form-item>
				<el-form-item label="需求描述"><el-input type="textarea" v-model="form.title" placeholder="请填写需求描述"></el-input></el-form-item>
			</el-form>

			<div class="subtmit-btn"><el-button type="primary" @click="onSubmit">提交</el-button></div>
		</div>
	</div>
</template>

<script>
import { HallForm, DemandName } from '@/common/js/api';
export default {
	name: 'submit_form',
	data() {
		return {
			form: {
				//  member_id: JSON.parse(localStorage.getItem("user")).id,
				corporate_name: '',
				name: '',
				phone: '',
				title: '',
				type: ''
			},
			type: []
		};
	},
	created() {
		this.getType();
	},
	methods: {
		getType() {
			DemandName()
				.then(res => {
					this.type = res.data;
				})
				.catch(() => {});
		},
		onSubmit() {
			HallForm(this.form)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('提交成功');
						this.form = {};
					}
				})
				.catch(() => {});
		}
	}
};
</script>
<style scoped lang="less">
//   请在下表留下您的信息
.submit-form-box {
	margin-top: 43px;
	padding: 43px 50px 45px;
	background-color: #3892eb;
	border-radius: 10px;
	.inner-form {
		background-color: #fff;
		border-radius: 10px;
		padding: 0 190px 42px 220px;
		.copy-con span {
			margin: 10px;
		}
		.wrap-sub-title {
			text-align: center;
			color: #696969;
			margin-bottom: 40px;
			font-size: 18px;
		}

		/deep/.el-form-item {
			.el-textarea__inner {
				height: 120px;
				line-height: 2;
				overflow-y: auto;
				/* overflow-y: auto;兼容ie  */
			}
		}

		.subtmit-btn {
			margin-top: 50px;
			display: flex;
			justify-content: center;
			.el-button--primary {
				width: 106px;
				font-size: 14px;
				background: #3892eb;
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.submit-form-box .inner-form{
		padding: 3rem;
	}
	.copy-con h3{
		font-size: 1.8rem;
	}
	.submit-form-box .inner-form .wrap-sub-title{
		font-size: 1.4rem;
	}
}
</style>
