<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 需求大厅 -->
    <HallNel></HallNel>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import HallNel from "./base/HallNel";
export default {
  name: "Hall",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
       this.ConList = item;
    },
  },
  components: {
    ConTab,
    HallNel,
  },
};
</script>

<style scoped>
</style>
